import React from 'react';
import PropTypes from 'prop-types';
import { getWebpageTemplateContent } from '@services/contentfulService';
import { WebpageTemplate } from '@components/Shared/WebpageTemplate/WebpageTemplate';

const Ebuilt = ({ pageInfo }) => <WebpageTemplate pageInfo={pageInfo} />;

export default Ebuilt;

const webPageKey = '3S2wDICu8cNaKH0RTEo9Wu';

export async function getServerSideProps() {
  let webPageContent;

  await Promise.all([
    getWebpageTemplateContent(webPageKey)
      .then((response) => {
        webPageContent = response;
      })
      .catch(() => {
        console.error('no content');
      }),
  ]);

  return {
    props: {
      pageInfo: webPageContent,
    },
  };
}

Ebuilt.propTypes = {
  pageInfo: PropTypes.object,
};
